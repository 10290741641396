.gallery-item img {
	width: 100%;
	height: auto;
	border-radius: 8px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	transition: transform 0.2s ease-in-out;
}

.gallery-item img:hover {
	/* transform: scale(1.05); */
}

img {
	width: 100%;
	height: auto;
	display: block;
}

/* masonry */
.gallery-grid {
	display: flex;
	justify-content: space-between;
	gap: 3em;
}

.gallery-column {
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 3em;
}

.gallery-item {
	margin-bottom: 1em;
}

/* alt text upon hover */
.gallery-item {
	position: relative;
	overflow: hidden;
	margin-bottom: 1em;
}

.gallery-item img {
	width: 100%;
	height: auto;
	display: block;
}

.alt-text {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: white;
	background-color: rgba(0, 0, 0, 0.9);
	padding: 0.5em;
	border-radius: 5px;
	opacity: 0;
	transition: opacity 0.3s ease;
	text-align: center;
	pointer-events: none;
}

.gallery-item:hover .alt-text {
	opacity: 1;
}

/* lightbox */
.lightbox {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.8);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
	cursor: pointer;
}

.lightbox-content {
	position: relative;
	max-width: 90%;
	max-height: 90%;
	overflow: hidden;
	background-color: #fff;
	padding: 1em;
	border-radius: 10px;
	cursor: default;
}

.lightbox-content img {
	display: block;
	max-width: 100%;
	max-height: 80vh;
}

.lightbox-alt-text {
	text-align: center;
	margin-top: 0.5em;
	color: #333;
}
